<template>
  <div style="position: relative">
    <div>
      <a-select
        ref="select"
        v-model:value="appIdVal"
        @change="appIdChange"
        style="width: 120px"
      >
        <a-select-option :value="''"> 全部 </a-select-option>
        <a-select-option
          v-for="item in appData"
          :key="item.dbName"
          :value="item.dbName"
          >{{ item.packageName }}</a-select-option
        >
      </a-select>
    </div>
    <div class="index-selected">
      <a-button id="select-all" style="margin-right: 10px">全选</a-button>
      <a-button id="unselect-all">全不选</a-button>
    </div>
    <a-spin :spinning="loading">
      <div id="main"></div>
    </a-spin>
  </div>
</template>

<script lang="ts" setup>
import * as echarts from "echarts";
import { nextTick, onMounted, watch, ref } from "vue";
import { prop } from "vue-class-component";
import dayjs, { Dayjs } from "dayjs";
import { sysDailyList } from "@/api/api";
const props = defineProps(["dataSource", "columns", "appData", "dateTime"]);
var myChart: any;

const appIdVal = ref("");
const tableData = ref([] as any[]);
const loading = ref(false);
let dateValue: (string | number | Date | dayjs.Dayjs | null | undefined)[] = [];
const appIdChange = (val: any) => {
  console.log(val);
  getDatas();
};
const seriesHandle = (newValue: any) => {
  let days: any[] = [];
  let series: any[] = [];
  let names: any[] = [];
  newValue.forEach((val: any) => {
    if (val.day && val.day != "汇总") {
      days.unshift(val.day);
    }
  });
  props.columns.forEach((item: any) => {
    if (item.title != "日期") {
      names.push(item.title);
      let seriesItem = {
        name: item.title,
        type: "line",
        stack: null,
        data: [] as any[],
      };
      newValue.forEach((val: any) => {
        if (val.day && val.day != "汇总") {
          let dataKeys = Object.keys(val);
          if (dataKeys.includes(item.key)) {
            let value = val[item.key];
            if (item.symbol) {
              value = (val[item.key] / 100).toFixed(2);
            }
            seriesItem.data.unshift(value);
          } else {
            if (item.key === "installUse") {
              let value = (
                val.fbNewUse /
                100 /
                (Number(val.newAndroidUser) +
                  Number(val.newIosUser) +
                  Number(val.reg))
              ).toFixed(2);
              seriesItem.data.unshift(value);
            }
            if (item.key === "regUse") {
              let value = (val.fbNewUse / 100 / Number(val.reg)).toFixed(2);
              seriesItem.data.unshift(value != "NaN" ? value : 0);
            }
            if (item.key === "rechargeAverageAmount") {
              let value = (
                val.rechargeTotalAmount /
                100 /
                val.successRecharge
              ).toFixed(2);
              seriesItem.data.unshift(value != "NaN" ? value : 0);
            }
            if (item.key === "vipAverageAmount") {
              let value = (
                val.vipTotalAmount /
                100 /
                val.successVipNum
              ).toFixed(2);
              seriesItem.data.unshift(value != "NaN" ? value : 0);
            }
            if (item.key === "svipAverageAmount") {
              let value = (
                val.svipTotalAmount /
                100 /
                val.successSvipNum
              ).toFixed(2);
              seriesItem.data.unshift(value != "NaN" ? value : 0);
            }
            if (item.key === "newPayUserAverageAmount") {
              let value = (
                val.newPayUserSumAmount /
                100 /
                val.newPayUserNum
              ).toFixed(2);
              seriesItem.data.unshift(value != "NaN" ? value : 0);
            }
          }
        }
      });

      series.push(seriesItem);
    }
  });
  nextTick(() => {
    myChart.setOption({
      legend: {
        orient: "vertical", // 垂直排列
        right: 30, // 距离右侧 10px
        top: "center", // 垂直居中
        data: names,
        // selected: {
        //   总消耗: false,
        // },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: days,
        axisLabel: {
          rotate: 45, // 旋转角度（例如 45°）
          interval: 0, // 强制全部显示（0 表示所有标签都显示）
        },
      },
      series: series,
      color: generateUniqueColors(names.length),
    });
    // 全选按钮
    document.getElementById("select-all")?.addEventListener("click", () => {
      const newSelected: any = {};
      names.forEach((name: any) => {
        newSelected[name] = true;
      });
      myChart.setOption({ legend: { selected: newSelected } });
    });

    // 全不选按钮
    document.getElementById("unselect-all")?.addEventListener("click", () => {
      const newSelected: any = {};
      names.forEach((name: any) => {
        newSelected[name] = false;
      });
      myChart.setOption({ legend: { selected: newSelected } });
    });
  });
};
const getDatas = () => {
  const params = {
    dbName: appIdVal.value,
    pageNo: 1,
    pageSize: 999,
    beginTime: dayjs(dateValue[0]).format("YYYY-MM-DD"),
    endTime: dayjs(dateValue[1]).format("YYYY-MM-DD"),
    isUse: 1,
  };
  loading.value = true;
  sysDailyList(params).then((res) => {
    console.log(res);
    loading.value = false;
    // total.value = res.data.total;
    if (res.code === "200") {
      var data: any[] = [];
      res.data.records.forEach((element: { day: string }) => {
        if (!element.day) {
          element.day = "汇总";
        }
        data.push(element);
      });
      tableData.value = data;

      seriesHandle(tableData.value);
    }
  });
};
const generateUniqueColors = (count: number): string[] => {
  const colors: string[] = [];

  while (colors.length < count) {
    // 生成随机颜色 (避免太亮，RGB 每个分量 < 240)
    const r = Math.floor(Math.random() * 200); // 限制到 200 以内，防止过亮
    const g = Math.floor(Math.random() * 200);
    const b = Math.floor(Math.random() * 200);

    const color = `rgb(${r},${g},${b})`;

    if (!colors.includes(color)) {
      colors.push(color);
    }
  }

  return colors;
};
watch(
  () => props.appData,
  (newValue) => {
    console.log("123123", newValue);
  },
  { immediate: true, deep: true }
);
// watch(
//   () => props.dataSource,
//   (newValue) => {
//     console.log(newValue);
//     seriesHandle(newValue);
//   },
//   { immediate: true, deep: true }
// );
watch(
  () => props.dateTime,
  (newValue) => {
    console.log(newValue);
    dateValue = newValue;
    getDatas();
  },
  { immediate: true }
);
watch(
  () => props.columns,
  (newValue) => {},
  { immediate: true }
);
onMounted(() => {
  // 基于准备好的dom，初始化echarts实例
  myChart = echarts.init(document.getElementById("main"));
  // 绘制图表
  myChart.setOption({
    title: {
      text: "",
    },
    tooltip: {
      trigger: "axis",
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
        },
      },
      right: 100,
      top: 10,
      orient: "vertical", // 垂直排列
    },
    legend: {
      orient: "vertical", // 垂直排列
      right: 30, // 距离右侧 10px
      top: "center", // 垂直居中
      data: [],
    },
    grid: {
      left: "3%",
      right: "11%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [],
    },
    yAxis: {
      type: "value",
    },
    series: [],
  });
});
</script>

<style lang="less" scoped>
#main {
  width: 100vw;
  height: calc(100vh - 200px);
}

.index-selected {
  position: absolute;
  right: 20px;
  top: 0;
}
</style>
